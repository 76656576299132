.ServOpt {
    display: flex; /* Use flexbox to create a flex container */
    width: 95vw; /* Set the width of the container to 95% of the viewport width */
    height: 30vh; /* Set the height of the container to 30% of the viewport height */
    align-items: center; /* Vertically center the elements inside the container */
    padding-left: 2.5vw;
  }
  
  .ServOpt > div {
    flex: 1; /* Make both elements take up an equal amount of space inside the container */
    margin: 5px; /* Add some margin between the elements for spacing */
  }
  
  /* Optional: Styling for the elements inside the container */
  .element1 {
    background-color: #f0f0f0;
    /* Additional styling for Element 1 */
  }
  
  .element2 {
    background-color: #eaeaea;
    /* Additional styling for Element 2 */
  }