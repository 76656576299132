.webHeader{
  text-align: center;
  height: 7vh;
  color: black;
  font-size: x-large;
  padding-bottom: 100px;
}

.body{
  width: 100vw;
  background-image: url('./Image 1.png');
  background-size: cover;
  background-position: center center;
  background-color: rgba(178, 100, 0, 0.8);
}

button{
  cursor: pointer;
}

Link{
  text-decoration: none;
}

a{
  text-decoration: none;
}

button:active{
  transform: scale(0.98);
}

/* CSS for your Navi component */

.NavBar{
  position: relative;
  top: 0;
  z-index: 1000;
  max-height: 20vh;
  margin-bottom: 15vh;
}

.Top-Navi{
  display:flex;
  min-height: 20vh;
  max-height: 20vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url('./Anotha One.jpg');
  background-size: cover;
  background-position: center center;

}

.Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100%;
  width: 20%;
  left: 0;
}


.App-logo {
  position: absolute; /* Position the background photo absolutely */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the image */
  height: 100%;
  display: block; /* This ensures the image takes up its natural space */
}

@keyframes loadingRotate{
  from {
   transform: rotate(0deg);
  }
 to {
  transform: rotate(359deg);
 }
}

.loading{
  animation: loadingRotate 2s linear infinite;
  height: 50%;
  width: auto;
  max-width: 50%;
}

.loading-page {
  align-items: center;
  width: 95%;
  position: absolute;
  margin: 5%;
  height: 30vh;
  text-align: center;
}

.company-title{
  display: flex;
  color: #E1C16E;
  min-height: 100%;
  width:auto;
  max-width: 75%;
  text-align: center;
  align-items: center;
  font-size:x-large;
}

@media screen and (max-width: 768px) {
  .company-title {
    max-width: 40%; /* Make it full-width on smaller screens */
    position: relative;
  }

  /* Adjust the positioning of the account-icon */
  .Account {
    right: 10px; /* Add some space to the right */
    height: 3rem;
    width: 3rem;
  }

  .Logo {
    width: 30%;
  }

  .App-logo {
    width: 250px;
  }

  .NavLink {
    margin-left: clamp(2px, 1.5vw, 10px);
    margin-right: clamp(2px, 1.5vw, 10px);
    font-size: 14px;
    width: 20%;
    text-align: center;
  }
  .navlinks {
    width: 80%
  }
}

.navlinks {
  width: 80%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
}

.NavLink {
  width: 20%;
  height: 100%;
  text-align: center;
  align-items: center;
}


.CartandAccount{
  position: absolute;
  top: 10;
  right: 0;
  min-height: 100%;
  min-width: 40%;
  max-width: 40%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding-right: 10px;
}

.Account {
  background-color: black;
  border: none;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  height: 4rem; /* Adjust the height as needed */
  width: 4rem; /* Adjust the width as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.CartIcon{
  background-color: rgba(0,0, 0, 0.1);
  height: 100%;
  border: #000;
  width: auto;
  z-index: 2;
}

.cart-item-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 12px;
}


.icon{
  cursor: pointer;
  color: #E1C16E;
  max-height: 75%;
  max-width: 75%;
}

.account-icon {
  color: rgba(225, 193, 110, 1);
  font-size: 24px;
  min-height: 90%;
  min-width: 90%;
  max-height: 100%; /* Set to 100% */
  max-width: 100%; /* Set to 100% */
  position: relative;
}

.account-icon:hover {
  transform: scale(1.1);
}

.social-icon{
  cursor: pointer;
  color: #E1C16E;
  height: 50px;
  width: 50px;
}

.BottomNavi{
  display:flex;
  height: 9vh;
  width: 100%;
  background-color: #fff;
  position: relative;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.NavLink:hover{
  border: 0;
  background: rgba(225, 193, 110, 0.6);
  box-shadow: 0 0 5px #E1C16E, 0 0 25px #E1C16E, 0 0 50px #E1C16E, 0 0 200px #E1C16E;
}

.account-content {
  background-color: #fff;
  min-width: 25%;
  min-height: 25%;
  max-width: auto;
  max-height: auto;
  padding: 20px;
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 10000;
}

/* Hamburger Menu Styling */

.caret{
  color:#E1C16E;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 10%;
  height: 75%;
  width: 25%;
}

.menu-line{
  display: flex;
  justify-content: space-around;
  position: relative;
  border-bottom: 2px solid #000;
  cursor: pointer;
}

/* Service Option Styles */

.ServOpt{
  width: 90%;
  max-width: 90%;
  height: 5vh;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.gun-option:hover, .services:hover{
  border: 0;
  background: rgba(225, 193, 110, 0.6);
  box-shadow: 0 0 5px #E1C16E, 0 0 25px #E1C16E, 0 0 50px #E1C16E;
}

.gun-option{
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* This makes a faint white with 20% opacity */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.services{
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8); /* This makes a faint white with 20% opacity */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.services-component {
  width: 95%;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
}

/* Style the cart to slide in from the right */
.Cart {
    position: fixed;
    top: 0;
    right: 0; /* Keep the cart visible by default */
    width: 400px;
    height: 100%;
    background-color: #fff;
    transition: right 1s ease-in-out;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 3;
    overflow: scroll;
  }
  
  /* Hide the cart when isCartOpen is false */
  .Cart.hidden {
    right: -300px;
  }
  .cart-content{
    overflow: scroll;
  }

  .cart-item{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-bottom: 2px;
  }

  .cart-item-title{
    font-weight: bold;
  }

  .cart-section{
    border-bottom: 2px solid #E1C16E;
    border-radius: 5px;
  }

  .cart-quantity {
    display: flex; /* Use flexbox to display children horizontally */
    align-items: center; /* Vertically center align children */
    justify-content: space-between;
    width: 75%;
    left: 0;
  }
  
  .cart-quantity p {
    margin-right: 10px; /* Add spacing between elements if needed */
  }

  .amount-in-cart{
    font-weight: bolder;
    text-decoration: underline;
  }
  
  .quantity-icon{
    height: 3%;
    width: 3%;
    cursor: pointer;
  }
  

  /* Styles for Gun Elements*/

  .GunList{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Create flexible columns */
    gap: 20px; /* Adjust the gap between items as needed */
  }

  .gun-element{
    /*display: inline-flex;*/
    height: auto;
    /*justify-content: center;*/
    /*align-items: center;*/
    background-color: rgba(178, 100, 0, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
    padding-left: 3%;
    margin-top: 1px;
  }
  .BuyButton{
    display: block;
    width: 20%;
    height: 15%;
    position: absolute;
    right: 3%;
    bottom: 3%;
    cursor: pointer;
    background-color: #E1C16E;
    border-radius: 10px;
  }
  .gun-title{
    display: flex;
    position: relative;
    width: 70%;
    height: 15%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: pointer;
  }
  .gun-image{
    width: 30%;
    height: 50%;
    flex-shrink: 0;
    position: absolute;
    right: 3%;
    top: 3%;
  }
  .gun-type{
    display: flex;
    width: 70%;
    height: 15%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  }
  .gun-price{
    display: flex;
    width: 70%;
    height: 15%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  }
  .gun-description{
    display: flex;
    width: 75%;
    height: 25%;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
  }

  .quantity-left{
    color: gray;
    font-style: italic;
  }
  
  /* Login Page Styles */

  .login-container{
    z-index: 1000;
    position: relative;
    top: 0;
    left: 0;
    min-width: 90%;
    min-height: auto;
    background-color: #E1C16E;
    border: 10px #000;
    border-radius: 15px;
    padding: 5px;
  }

  .userLoggedIn{
    z-index: 1000;
    position: relative;
    top: 0;
    left: 0;
    min-width: 90%;
    min-height: auto;
    background-color: #E1C16E;
    border: 10px #000;
    border-radius: 15px;
    padding: 5px;
  }

  /* Contact Us Form */

  .ContactUs {
    text-align: center;
    padding: 20px;    
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    width: 95%;
    height: auto;
  }
  
  .ContactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  
  .FormElement {
    margin: 10px 0;
    min-width: 90%;
    max-width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .FormElement textarea {
    resize: vertical;
  }
  
  .FormElement label {
    display: block;
    text-align: left;
    font-weight: bold;
  }
  
  .FormElement button {
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .FormElement button:hover {
    background-color: #E1C16E;
  }

  @media (max-width: 768px) {
    .FormElement {
      margin: 5px 0;
      min-width: 40%;
      max-width: 80%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 14px;
    }
    .tab-content.active{
      max-width: 550px;
      margin: 0 auto;
    }
    .tab-navigation{
      justify-content: space-around;
      width: 95%;
      margin: 0 3px;
    }
  }
  

  .connect-with-us{
    display: inline-block;
  }

  .socials{
    display: flex;
    justify-content: space-between;
    width: 40%;
  }
  
  .submitted-successfully{
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    margin-top: 10vh;
  }

  /* Popout Styles */
  .open-modal {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; 
  }
  
  .modal {
    background-color: #fff;
    min-width: 90%;
    min-height: 90%;
    max-width: 90%;
    max-height: 90%;
    padding: 20px;
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both horizontally and vertically */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 10000;
  }
  
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #888;
  }

/* Common styles for the button */
.request-service-button {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
  border: 3px solid #E1C16E;
  border-radius: 15px 0px;
  padding: 20px;
  z-index: 1;
}

/* Mobile Optimized: Adjust the button size and padding for smaller screens */
@media screen and (max-width: 768px) {
  .request-service-button {
    width: 90%; /* Make it full width on smaller screens */
    max-width: 90%; /* Limit the maximum width to 100% */
    padding: 10px; /* Reduce padding for smaller screens */
    position: relative; /* Change position to relative */
    right: auto; /* Reset right position */
  }
  .review-area {
    top: 10px;
  }
  .footer {
    font-size: 10px;
  }
  .service-modal-item {
    font-size: 12px;
  }
  .request-service {
    font-size: 12px;
  }
}

  .service-description{
    position: relative;
    left: 0%;
    max-width: 60%;
  }

  .request-service-button:hover{
    background: #E1C16E;
  }  

  .open-modal {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}  
  .service-modal {
    background-color: #fff;
    min-width: 90%;
    min-height: 90%;
    max-width: 90%;
    max-height: 90%;
    padding: 20px;
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both horizontally and vertically */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 10000;
    display: flex;
    justify-content: space-evenly;
  }
  
  .service-modal-item{
    max-width: 50%;
    overflow: scroll;
  }

  .request-service{
    max-width: 50%;
    overflow: scroll;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #888;
  }
  
  .order-component{
    width: 95%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
  }
  
  .order-title{
    height: auto;
    width: 100%;
    position: relative;
    background-color: #E1C16E;
    color: #000;
    margin: 3px auto;
    align-items: center;
    text-align: start;
    cursor: pointer;
    transition: margin 0.3s ease-in-out;
    border-radius: 15px;
    padding-left: 3px;
  }

  .profile-page{
    align-items: center;
    text-align: center;
  }

  .footer{
    background: #000;
    color: #fff;
    width: 100%;
    position: relative;
    left: 0;
  }

  .threeColumns{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  footerColumn{
    width: 33%;
    align-items: center;
  }

  .dropdown-content ul{
    position: relative;
    top: 0;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }

  .modal-item{
    min-width: 100%;
    min-height: 70%;
  }

  .modal-photo{
    max-width: 40%;
    max-height: 30%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-button{
    width: 20%;
    height: 20%;
    position: relative;
    left: 80%;
    cursor: pointer;
    background-color: #E1C16E;
    border-radius: 10px;
  }

.dropdown-item {
  width: 100%;
  position: relative;
  background: url('../../public/Images/Distressed\ Brass.jpg');
  background-size: cover;
  background-position: center center;
  /*
  background: linear-gradient(to right, rgba(187, 135, 75, 1) 0%, rgba(224, 171, 92, 1) 100%);
  */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  padding-right: 5px;
  margin: 3px auto;
  text-align: start;
  cursor: pointer;
  transition: margin 0.8s ease-in-out;
  border-radius: 15px;
  padding-left: 3px;
  display: flex; /* Add flex container */
  flex-direction: column; /* Stack items vertically */
}

.dropdown-item.active {
  margin-bottom: auto;
  border-bottom: 1px solid black;
  border-radius: 15px;
  transition: margin 0.8s ease-in-out;
}

.dropdown-content {
  width: 100%;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid black;
  border-radius: 15px;
  padding-left: 3px;
  position: relative;
  text-align: left;
  display: flex; /* Add flex container */
  flex-direction: column; /* Stack items vertically */
  flex: 1; /* Allow content to flex and fill available space */
  transition: max-height 0.5s ease-in-out; /* Slower transition speed */
}

.review-content{
  display: none;
}
  
.review-content.active {
  width: 100%;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid black;
  border-radius: 15px;
  padding-left: 3px;
  padding-top: 15px;
  position: relative;
  text-align: left;
  display: flex; /* Add flex container */
  flex-direction: column; /* Stack items vertically */
  flex: 1; /* Allow content to flex and fill available space */
  transition: max-height 0.5s ease-in-out; /* Slower transition speed */
}

.dropdown-title {
  padding-left: 5px;
  padding-top: 5px;
}

.services-caret {
  display: inline-block;
  position: absolute;
  right: 5%;
  top: 40%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #080808 transparent transparent transparent;
}

.services-caret.caret-up {
  transform: rotate(180deg);
}

  .review-area {
    background-color: rgba(178, 100, 0, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    padding-right: 5px;
    margin: 3px auto;
    text-align: start;
    cursor: pointer;
    transition: margin 0.8s ease-in-out;
    border-radius: 15px;
    padding-left: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 90%;
    margin-bottom: 30px;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    -webkit-scrollbar-width: thin;
    -webkit-scrollbar-color: transparent transparent;
    overflow: auto;
    border: 1px solid #000;
  }

  .review-area.active {
    margin-bottom: auto;
    border-bottom: 1px solid black;
    border-radius: 15px;
    transition: margin 0.8s ease-in-out;
  }

  .review{
    width: 100%;
    height: auto;
    padding: 5px;
  }

  .review-form{
    min-width: 100%;
  }

  .write-reviews{
    position: relative;
    display: flex;
    min-width: 100%;
  }

/* Style for the tab navigation buttons */
.tab-navigation {
  display: flex;
  flex-wrap: wrap; /* Allow tabs to wrap onto the next line */
  gap: 10px;
}

.tab-button {
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.tab-button.active {
  background-color: #E1C16E; /* Change this to your desired active tab color */
  color: black;
}

/* Style for the tab content */
.tab-content {
  display: none;
  padding: 10px;
  border: 1px solid #ccc;
}

.firearm-description{
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
}

.tab-content.active {
  display: block;
  width: auto;
  margin-top: 10px;
  height: auto;
  max-width: 550px;
}

  .error-message{
    color: red;
    font-size: large;
    font-weight: bold;
  }

  .success-message{
    color: lightgreen;
    font-size: large;
    font-weight: bold;
  }

  /* Add this CSS to your stylesheet */
@keyframes jiggle {
  0% {
    transform: translateX(-2px); /* Move text 2 pixels to the left */
  }
  25% {
    transform: translateX(2px); /* Move text 2 pixels to the right */
  }
  50% {
    transform: translateX(-2px); /* Move text 2 pixels to the left */
  }
  75% {
    transform: translateX(2px); /* Move text 2 pixels to the right */
  }
  100% {
    transform: translateX(0); /* Back to the original position */
  }
}

/* Apply the jiggle animation to the element when it mounts */
.text-mounting {
  animation: jiggle 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
}

.carousel-header{
  text-align: center;
  height: 7vh;
  color: black;
  font-size: x-large;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .carousel-header{
    height: 3vh;
    font-size: medium;
    margin-bottom: 100px;
    padding-bottom: 50px;
  }
}

.carousel-header a{
  color: black;
}

.carousel{
  width: auto;
  border-radius: 5px;
  padding-bottom: 10px; 
  display: block;
}

/* Gallery Page Styles */

.gallery-page{
  width: 100%;
  align-items: center;
  text-align: center;
}

.gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.picture-box{
  width: 20%;
  height: 25%;
  background-color: lightgrey;
  border-radius: 5px;
  padding-top: 5px;
}

.gallery-photo{
  width: 85%;
  height: 70%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.checkout-modal {
  background-color: #fff;
  padding: 20px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 10000;
}

.choose-checkout{
  position: relative;
  min-height: 95%;
  min-width: 95%;
  align-items: center;
  text-align: center;
  display: block;
  align-content: center;
}

.two-sections{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
}

.checkout-information{
  width: 50%;
}

.cart-review{
  width: 50%;
}

.AboutUsText{
  text-align: center;
  padding: 20px;    
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 95%;
  height: auto;
}

.HomePage{
  padding: 20px;    
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 95%;
  height: auto;
}

/* Rich Text Editor Styling */
/* Wrapper for the editor */
.editor-container {
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 90%;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

/* Toolbar styles */
.toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px;
}/* Default style for toolbar buttons */
.toolbar button {
  width: 20%;
  background-color: #fff;
  border: 1px solid #E1C16E;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  margin-top: 5px;
}

/* Style for active (selected) buttons */
.toolbar button.active {
  background-color: #E1C16E;
}

/* Hover style */
.toolbar button:hover:not(.active) {
  background-color: #E1C16E;
}


/* Editor content styles */
.editor-content {
  border: 1px solid black;
  border-radius: 5px;
  min-height: 200px;
  padding: 10px;
  z-index: -1;
}

/* Text styles */
.editor-content p {
  margin: 0;
  line-height: 1.5;
}

/* Headings */
.editor-content h1, .editor-content h2, .editor-content h3 {
  font-weight: bold;
  margin: 10px 0;
}

/* Lists */
.editor-content ul, .editor-content ol {
  margin: 10px 0;
  padding-left: 20px;
}

/* Links */
.editor-content a {
  color: #007bff;
  text-decoration: underline;
}

/* Blockquotes */
.editor-content blockquote {
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border-left: 4px solid #007bff;
}

/* Code blocks */
.editor-content pre {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 3px;
  overflow: auto;
}

/* Inline code */
.editor-content code {
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
  font-family: monospace;
}

/* Images */
.editor-content img {
  max-width: 100%;
  height: auto;
}

.OwnersPortal{
  display: inline-flex;
  width: 95%;
  margin-top: 10px;
  justify-content: space-around;
}

.OwnersOrders{
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
  margin: 3px;
  width: 40%;
  height: 500px;
  overflow: scroll;
}

.OwnersServiceUpdate{
  border: 2px solid black;
  border-radius: 5px;
  padding: 15px 5px 5px 5px;
  margin: 3px;
  width: 45%;
  height: auto;
}