/* FeaturedCarousel.css */

/* Style for the outer carousel container */
.featured-carousel {
    width: 85vw; /* Set the carousel container to 85% of viewport width */
    margin: 0 auto;
    justify-content: space-around;
    display: flex;
    flex-wrap: nowrap; /* Prevent slides from wrapping onto a second row */
    align-items: center; /* Center the slides vertically */
    position: relative;
    z-index: 1;
  }
  
  /* Style for individual slides */
  .featured-slide {
    display: flex;
    position: relative;
    background-color: rgba(255, 255, 255, 0.8); /* This makes a faint white with 20% opacity */
    align-items: center;
    text-align: center;
    min-width: 85%;
    width: 85%; /* Set each slide to 85% of the parent container width */
    /*max-width: 400px;  You can adjust this value to limit the maximum width of each slide */
    margin: 0 auto;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
  
  .carousel-photo {
    max-width: 15%; /* Ensure the image doesn't exceed the width of the slide */
    max-height: 15%; /* Ensure the image doesn't exceed the height of the slide */
    width: auto; /* Auto-adjust the width to maintain aspect ratio */
    height: auto; /* Auto-adjust the height to maintain aspect ratio */
    margin: 0 auto; /* Center horizontally within the slide */
    vertical-align: middle; /* Center vertically within the slide */
  }
  
.slick-prev, .slick-next {
  color: black; /* Set the desired arrow color */
}

.featured-button{
  width: 50%;
  height: 25%;
  background-color: #E1C16E;
  border-radius: 15px 0 15px 0;
  height: 40px;
}
