/* Hamburger styles */
.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 30px;
    height: 25px;
    cursor: pointer;
  }
  
  .line {
    width: 30px;
    height: 2px;
    background-color: #333;
  }
  
  /* Menu styles */
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 250px;
    background-color: #fff;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
    transform: translateX(250px);
    transition: transform 0.3s ease-in-out;
    z-index: 100000;
  }
  
  .menu.open {
    transform: translateX(0);
  }
  
  .menu ul {
    list-style: none;
    padding: 20px;
  }
  
  .menu ul li {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .menu ul li:hover{    
  border: 0;
  background: rgba(225, 193, 110, 0.6);
  box-shadow: 0 0 5px #E1C16E, 0 0 25px #E1C16E, 0 0 50px #E1C16E;
  }

/* Caret styles */
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .caret.down {
    transform: rotate(180deg);
  }
  
  /* Sub-menu styles */
  .sub-menu {
    list-style: none;
    padding-left: 20px;
  }
  
  .sub-menu li {
    margin-bottom: 15px;
    font-size: 16px;
  }
/* Sub-menu styles */
.sub-menu {
    list-style: none;
    padding-left: 20px;
  }
  
  .sub-menu li {
    margin-bottom: 15px;
    font-size: 16px;
  }
    
  /* Adjust the styles as needed to match your design preferences */
  